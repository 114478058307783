import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [5,[2],[3]],
		"/(app)/checklists/[operation=new_edit_copy]/[[id]]": [6,[2],[3]],
		"/(app)/contacts": [7,[2],[3]],
		"/(app)/documents": [8,[2],[3]],
		"/(app)/documents/[id]": [9,[2],[3]],
		"/(app)/get-help": [10,[2],[3]],
		"/(public)/invitation/[id]": [29,[4]],
		"/(app)/lessons/[operation=new_edit_copy]/[[id]]": [11,[2],[3]],
		"/(app)/lynkt-import": [12,[2],[3]],
		"/(app)/organization-settings": [13,[2],[3]],
		"/(app)/organizations": [14,[2],[3]],
		"/(app)/products": [15,[2],[3]],
		"/(app)/products/[productId]": [16,[2],[3]],
		"/(app)/products/[productId]/inventory": [18,[2],[3]],
		"/(app)/products/[productId]/inventory/[instanceId]/[operation=new_edit_copy]": [19,[2],[3]],
		"/(app)/products/[productId]/[operation=new_edit_copy]": [17,[2],[3]],
		"/(app)/profile": [20,[2],[3]],
		"/(app)/tasks": [21,[2],[3]],
		"/(app)/tasks/[operation=new_edit_copy]/[[id]]": [26,[2],[3]],
		"/(app)/tasks/[id]": [22,[2],[3]],
		"/(app)/tasks/[id]/instances/[instanceId]": [23,[2],[3]],
		"/(app)/tasks/[id]/instances/[instanceId]/checklists/[checklistId]": [24,[2],[3]],
		"/(app)/tasks/[id]/instances/[instanceId]/checklists/[checklistId]/report": [25,[2],[3]],
		"/(app)/users": [27,[2],[3]],
		"/(app)/workflows/[operation=new_edit_copy]/[[id]]": [28,[2],[3]],
		"/[...path]": [30]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';